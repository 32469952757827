import { BenefitProps } from './types'

export const getDefaultBenefits = (): BenefitProps[] => {
	return [
		{
			icon: 'GenderLight',
			text: 'Biological sex',
		},
		{
			icon: 'BarCustom',
			text: 'Current sleep stages',
		},
		{
			icon: 'CalendarLight',
			text: 'Your age',
		},
		{
			icon: 'TemperatureLight',
			text: 'Preferred temperature',
		},
		{
			icon: 'SleepScore',
			text: 'REM sleep score',
		},
		{
			icon: 'WeatherCustom',
			text: 'Environmental temperature',
		},
	]
}
