import { FC, useEffect, useRef, useState } from 'react'
import styles from './AutopilotHero.module.scss'
import { AutopilotHeroProps, BenefitProps } from './types'
import { getDefaultBenefits } from './utils'
import { Icon } from 'components/Phantom/Icon'
import {
	BufferAttribute,
	BufferGeometry,
	Clock,
	Color,
	DoubleSide,
	InstancedBufferAttribute,
	InstancedMesh,
	Object3D,
	PerspectiveCamera,
	ReinhardToneMapping,
	Scene,
	ShaderMaterial,
	WebGLRenderer,
} from 'three'
import { map } from 'components/_utils/mathUtils'
import { isWebGLAvailable } from 'components/_utils/threeUtils'
import { shuffleArray } from 'components/_utils/arrayUtils'
import { BlendFunction, EffectComposer, EffectPass, RenderPass, SelectiveBloomEffect } from 'postprocessing'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger'
import { Type } from 'components/Type'

export const AutopilotHero: FC<AutopilotHeroProps> = (props) => {
	const containerRef = useRef<HTMLDivElement>(null)
	const cameraRef = useRef<PerspectiveCamera>(null)

	const [containerVisible, setContainerVisible] = useState(false)

	const [threeLoaded, setThreeLoaded] = useState(false)

	const { benefits = getDefaultBenefits() } = props

	useGSAP(() => {
		if (threeLoaded) {
			gsap.registerPlugin(ScrollTrigger)
			gsap.fromTo(
				cameraRef.current.position,
				{
					z: 140,
				},
				{
					z: 200,
					scrollTrigger: {
						trigger: containerRef.current,
						scrub: 1,
						start: 'top top',
						end: `+=${window.innerHeight}px`,
					},
				}
			)

			gsap.to('#autopilot-canvas', {
				filter: 'blur(10px)',
				ease: 'power1.out',
				scrollTrigger: {
					trigger: containerRef.current,
					scrub: 1,
					start: '500 top',
					end: `+=${window.innerHeight}px`,
				},
			})

			gsap.to('#autopilot-hero', {
				y: 100,
				opacity: 0,
				scrollTrigger: {
					trigger: containerRef.current,
					scrub: true,
					start: 'top top',
					end: `+=${window.innerHeight / 2}px`,
				},
			})

			gsap.from('#autopilot-blueprint', {
				opacity: 0,
				y: 100,
				ease: 'power1.out',
				duration: 1,
				scrollTrigger: {
					trigger: '#autopilot-blueprint',
					start: 'top center',
					toggleActions: 'restart none none reverse',
				},
			})

			gsap.from('#autopilot-benefits li', {
				opacity: 0,
				delay: 0.5,
				scrollTrigger: {
					trigger: '#autopilot-blueprint',
					start: 'top center',
					toggleActions: 'restart none none reverse',
				},
			})

			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: '#autopilot-blueprint',
					start: 'top center',
					end: 'bottom center',
					scrub: 2,
				},
			})

			gsap.utils.toArray('#autopilot-benefits li').forEach((layer: HTMLElement, i) => {
				const depth = ((i + 2) % (benefits.length / 2)) + 1 + i / 2
				const movement = -(100 * depth)
				tl.to(layer, { y: movement, ease: 'none' }, 0)
			})
		}
	}, [threeLoaded])

	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				setContainerVisible(entry.isIntersecting)
			})
		})

		observer.observe(containerRef.current)

		return () => {
			observer.disconnect()
		}
	}, [])

	return (
		<div
			className={styles.container}
			ref={containerRef}
		>
			<div className={styles.canvas_rails}>
				<div
					className={styles.canvas_container}
					style={{
						display: containerVisible ? 'block' : 'none',
					}}
				>
					<AutopilotBall
						setThreeLoaded={(camera) => {
							setThreeLoaded(true)
							cameraRef.current = camera
						}}
						bgColor={'#000000'}
					/>
				</div>
			</div>
			<section
				className={styles.hero}
				id={'autopilot-hero'}
			>
				<header>
					<Type.Eyebrow>Autopilot</Type.Eyebrow>
					<Type.MegaHeadline>The intelligence behind the Pod</Type.MegaHeadline>
				</header>
			</section>
			<section
				className={styles.blueprint}
				id={'autopilot-blueprint'}
			>
				<header>
					<Type.Headline2>Autopilot creates an individual blueprint of you</Type.Headline2>
					<Type.Body1>Everyone sleeps differently. Autopilot looks at all your different factors to create a unique blueprint of your sleep needs.</Type.Body1>
				</header>
				<ul
					className={styles.benefits}
					id={'autopilot-benefits'}
				>
					{benefits.map((benefit, i) => (
						<li key={benefit.text}>
							<Benefit
								{...benefit}
								key={i}
							/>
						</li>
					))}
				</ul>
			</section>
		</div>
	)
}

export const AutopilotBall = (props: { setThreeLoaded?: (camera: PerspectiveCamera) => void; bgColor?: string; noBloom?: boolean }) => {
	const canvasRef = useRef<HTMLCanvasElement>(null)

	useEffect(() => {
		const canvas = canvasRef.current
		if (!canvas) return () => {}
		const parent = canvas.parentElement
		if (!parent) return () => {}
		if (!isWebGLAvailable()) return () => {}

		const parentWidth = parent.clientWidth || window.innerWidth
		const parentHeight = parent.clientHeight || window.innerHeight

		const scene = new Scene()
		const camera = new PerspectiveCamera(75, parentWidth / parentHeight, 0.1, 1000)
		camera.position.z = 200

		const renderer = new WebGLRenderer({ canvas, powerPreference: 'high-performance' })
		renderer.setPixelRatio(Math.max(window.devicePixelRatio, 2))
		renderer.setSize(parentWidth, parentHeight)
		renderer.toneMapping = ReinhardToneMapping
		renderer.setClearColor(0x000000, 0)

		const numParticles = 1525
		const positions = fibonacciSphere(numParticles)

		const mesh = createParticles(positions)
		scene.add(mesh)

		const composer = new EffectComposer(renderer)

		const renderPass = new RenderPass(scene, camera)
		composer.addPass(renderPass)

		const bloomEffect = new SelectiveBloomEffect(scene, camera, {
			blendFunction: BlendFunction.ADD,
			mipmapBlur: true,
			luminanceThreshold: 0,
			luminanceSmoothing: 0.2,
			intensity: 5,
		})
		bloomEffect.inverted = true
		bloomEffect.dithering = true

		const bloomPass = new EffectPass(camera, bloomEffect)

		if (!props.noBloom) {
			composer.addPass(bloomPass)
		}

		const clock = new Clock()
		clock.start()
		let animationFrameId: number

		if (props.bgColor) {
			mesh.material.uniforms.bgColor.value = new Color(props.bgColor)
		}

		const animate = () => {
			animationFrameId = requestAnimationFrame(animate)

			mesh.material.uniforms.uTime.value = clock.getElapsedTime()
			composer.render()
		}

		animationFrameId = requestAnimationFrame(animate)

		if (props.setThreeLoaded) {
			props.setThreeLoaded(camera)
		}

		// Cleanup function
		const resizeCanvas = () => {
			const parentWidth = parent.clientWidth || window.innerWidth
			const parentHeight = parent.clientHeight || window.innerHeight
			canvas.width = parentWidth
			canvas.height = parentHeight
			renderer.setSize(parentWidth, parentHeight)
			composer.setSize(parentWidth, parentHeight)
			camera.aspect = parentWidth / parentHeight
			camera.updateProjectionMatrix()
		}
		resizeCanvas()
		window.addEventListener('resize', resizeCanvas)

		return () => {
			cancelAnimationFrame(animationFrameId)

			renderer.dispose()
			window.removeEventListener('resize', resizeCanvas)
		}
	}, [props.bgColor])

	return (
		<canvas
			id={'autopilot-canvas'}
			className={styles.canvas}
			ref={canvasRef}
		/>
	)
}

const Benefit: FC<BenefitProps> = (props) => {
	return (
		<div className={styles.benefit}>
			<Icon
				name={props.icon}
				color={'white'}
				size={24}
			/>
			<Type.Body2>{props.text}</Type.Body2>
		</div>
	)
}

const phi = Math.PI * (3 - Math.sqrt(5))

const fibonacciSphere = (numPoints = 1000) => {
	const points = []

	for (let i = 0; i < numPoints; i++) {
		const y = map(i, 0, numPoints - 1, -1, 1)
		const radiusAtY = Math.sqrt(1 - y * y)

		const theta = phi * i // golden angle increment

		const x = Math.cos(theta) * radiusAtY
		const z = Math.sin(theta) * radiusAtY
		points.push([x, y, z])
	}
	const shuffledPoints = shuffleArray(points)
	return shuffledPoints.flat()
}

export const material = new ShaderMaterial({
	vertexShader: `
    // Vertex attributes
	attribute vec3 instanceOffset;// Position offset for each instance
	
	// Varying variables to pass data to the fragment shader
	varying vec3 vColor;
	varying vec2 vUv;
	varying float vSparklePhase;
	varying float vDepthFade;
	
	uniform vec3 color1;  // First color vector
	uniform vec3 color2;  // Second color vector
	
	uniform float uTime;
	uniform float circleRadius;
	uniform float ballRadius;
	
	uniform float uRandomPhiOffsetAmount;
	uniform float uRandomThetaOffsetAmount;
	
	uniform float uRadialDisplacementNoisiness;
	uniform float uRadialDisplacementAmplitude;
	uniform float uRadialDisplacementSpeed;
	
	uniform float uPhiDisplacementNoisiness;
	uniform float uPhiDisplacementAmplitude;
	uniform float uPhiDisplacementSpeed;
	
	uniform float uThetaDisplacementNoisiness;
	uniform float uThetaDisplacementAmplitude;
	uniform float uThetaDisplacementSpeed;
	
	uniform float uRotationSpeedX;
	uniform float uRotationSpeedY;
	uniform float uRotationSpeedZ;
	
    
    float random(vec2 st) { 
    	return fract(sin(dot(st.xy, vec2(123.9898, 781.233))) * 437548.5453123);
	}
	
	vec4 mod289(const in vec4 x) { return x - floor(x * (1. / 289.)) * 289.; }
	
	vec4 permute(const in vec4 v) { return mod289(((v * 34.0) + 1.0) * v); }
	
	vec4 taylorInvSqrt(in vec4 r) { return 1.79284291400159 - 0.85373472095314 * r; }
	
	vec4 grad4(float j, vec4 ip) {
		const vec4 ones = vec4(1.0, 1.0, 1.0, -1.0);
		vec4 p, s;
		p.xyz = floor(fract (vec3(j) * ip.xyz) * 7.0) * ip.z - 1.0;
		p.w = 1.5 - dot(abs(p.xyz), ones.xyz);
		s = vec4(lessThan(p, vec4(0.0)));
		p.xyz = p.xyz + (s.xyz*2.0 - 1.0) * s.www;
		return p;
	}
	
	vec2  quintic(const in vec2 v)  { return v*v*v*(v*(v*6.0-15.0)+10.0); }
	
	
	float pnoise(in vec2 P, in vec2 rep) {
		vec4 Pi = floor(P.xyxy) + vec4(0.0, 0.0, 1.0, 1.0);
		vec4 Pf = fract(P.xyxy) - vec4(0.0, 0.0, 1.0, 1.0);
		Pi = mod(Pi, rep.xyxy);// To create noise with explicit period
		Pi = mod289(Pi);// To avoid truncation effects in permutation
		vec4 ix = Pi.xzxz;
		vec4 iy = Pi.yyww;
		vec4 fx = Pf.xzxz;
		vec4 fy = Pf.yyww;
	
	
	
		vec4 i = permute(permute(ix) + iy);
	
		vec4 gx = fract(i * (1.0 / 41.0)) * 2.0 - 1.0;
		vec4 gy = abs(gx) - 0.5;
		vec4 tx = floor(gx + 0.5);
		gx = gx - tx;
	
	
	
		vec2 g00 = vec2(gx.x, gy.x);
		vec2 g10 = vec2(gx.y, gy.y);
		vec2 g01 = vec2(gx.z, gy.z);
		vec2 g11 = vec2(gx.w, gy.w);
	
		vec4 norm = taylorInvSqrt(vec4(dot(g00, g00), dot(g01, g01), dot(g10, g10), dot(g11, g11)));
		g00 *= norm.x;
		g01 *= norm.y;
		g10 *= norm.z;
		g11 *= norm.w;
	
	
		float n00 = dot(g00, vec2(fx.x, fy.x));
		float n10 = dot(g10, vec2(fx.y, fy.y));
		float n01 = dot(g01, vec2(fx.z, fy.z));
		float n11 = dot(g11, vec2(fx.w, fy.w));
	
		vec2 fade_xy = quintic(Pf.xy);
		vec2 n_x = mix(vec2(n00, n01), vec2(n10, n11), fade_xy.x);
		float n_xy = mix(n_x.x, n_x.y, fade_xy.y);
	
	
		return 2.3 * n_xy;
	}

    void main() {
	    float t = (instanceOffset.y + 1.0) / 2.0; // Map instanceOffset.y from [-1, 1] to [0, 1]
	    vColor = mix(color1, color2, t);
	
		vec3 newOffset = instanceOffset * ballRadius;
		
		// Generate a unique phase for each particle based on its offset
		vSparklePhase = random(vec2(newOffset.xy));

		
		
		float sphereRadius = length(newOffset);
		float theta = acos(newOffset.y / sphereRadius); // polar angle
		float phi = atan(newOffset.z, newOffset.x); // azimuthal angle
		
		float randomPhiOffset = random(vec2(newOffset.x, vSparklePhase)) - 0.5;
		float randomThetaOffset = random(vec2(randomPhiOffset, vSparklePhase)) - 0.5;
	
		phi += randomPhiOffset * uRandomPhiOffsetAmount;
		theta += randomThetaOffset * uRandomThetaOffsetAmount;
		
		vec2 phiNoiseCoords = vec2(phi, theta) * uPhiDisplacementNoisiness + uTime * uPhiDisplacementSpeed;
		vec2 phiRepetitionVector = vec2(63.2, 10.4);
		float displacedPhi = phi + (pnoise(phiNoiseCoords, phiRepetitionVector) * 0.5 + 0.5) * uPhiDisplacementAmplitude;
		
		vec2 thetaNoiseCoords = vec2(phi, theta) * uThetaDisplacementNoisiness + uTime * uThetaDisplacementSpeed;
		vec2 thetaRepetitionVector = vec2(43.2, 30.4);
		float displacedTheta = theta + (pnoise(thetaNoiseCoords, thetaRepetitionVector) * 0.5 + 0.5) * uThetaDisplacementAmplitude;
		
		float waveDisplacement =  sin((displacedTheta / 3.1415) * 4.0 + uTime * 1.75);
		
		vec2 radiusNoiseCoords = vec2(displacedTheta, displacedPhi) * uRadialDisplacementNoisiness + uTime * uRadialDisplacementSpeed;
		vec2 radiusRepetitionVector = vec2(43.2, 30.4);
		float displacedRadius = sphereRadius + (pnoise(radiusNoiseCoords, radiusRepetitionVector) * 0.5 + 0.5) * uRadialDisplacementAmplitude + waveDisplacement * 5.0 -  2.5;
	
		newOffset.x = displacedRadius * sin(displacedTheta) * cos(displacedPhi);
		newOffset.y = displacedRadius * cos(displacedTheta);
		newOffset.z = displacedRadius * sin(displacedTheta) * sin(displacedPhi); 
		
		float rotationAngleX = uTime * uRotationSpeedX;
		float rotationAngleY = uTime * uRotationSpeedY;
		float rotationAngleZ = uTime * uRotationSpeedZ;
		
		mat4 rotationZ = mat4(
		cos(rotationAngleZ), -sin(rotationAngleZ), 0.0, 0.0,
		sin(rotationAngleZ), cos(rotationAngleZ), 0.0, 0.0,
		0.0, 0.0, 1.0, 0.0,
		0.0, 0.0, 0.0, 1.0
		);
		
		mat4 rotationY = mat4(
		cos(rotationAngleY), 0.0, sin(rotationAngleY), 0.0,
		0.0, 1.0, 0.0, 0.0,
		-sin(rotationAngleY), 0.0, cos(rotationAngleY), 0.0,
		0.0, 0.0, 0.0, 1.0
		);
		
		mat4 rotationX = mat4(
		1.0, 0.0, 0.0, 0.0,
		0.0, cos(rotationAngleX), -sin(rotationAngleX), 0.0,
		0.0, sin(rotationAngleX), cos(rotationAngleX), 0.0,
		0.0, 0.0, 0.0, 1.0
		);
		
		vec3 rotatedOffset = (rotationX * rotationY * rotationZ * vec4(newOffset, 1.0)).xyz;
	
		// Compute the camera-facing orientation
		vec3 lookDirection = normalize(rotatedOffset - cameraPosition);// Direction from the camera to the instance
		vec3 right = normalize(cross(vec3(0.0, 1.0, 0.0), lookDirection));// Right vector, orthogonal to the look direction and up (0, 1, 0)
		vec3 up = cross(lookDirection, right);// Up vector, orthogonal to the look direction and right vector
	
		// Construct a rotation matrix that will orient the quad towards the camera
		mat4 rotationMatrix = mat4(
		vec4(right, 0.0),
		vec4(up, 0.0),
		vec4(lookDirection, 0.0),
		vec4(0.0, 0.0, 0.0, 1.0) 
		);
	
		// Apply the rotation matrix to the position and add the instance offset
		vec4 rotatedPosition = rotationMatrix * vec4(position * (max(circleRadius + waveDisplacement * 0.35, 0.5)), 1.0);
		vec4 worldPosition = modelViewMatrix * (rotatedPosition + vec4(rotatedOffset, 0.0)); 
	
		// Calculate the final vertex position in clip space
		gl_Position = projectionMatrix * worldPosition;
		
		// Fade out far away particles
		vDepthFade = ((max(rotatedOffset.z, 0.0))/ ballRadius);
	
		// Calculate the UV coordinates for the fragment shader
		vUv = position.xy * 0.5 + vec2(0.5);
    }
  `,
	fragmentShader: `
	varying vec3 vColor;
	varying vec2 vUv;
	varying float vSparklePhase;
	varying float vDepthFade;
	
	uniform float uTime;
	uniform float sparkleAmount;
	uniform float sparkleSpeed;
	uniform vec3 bgColor;
	
	float random (vec2 st) {
		return fract(sin(dot(st.xy, vec2(12.9898, 78.233))) * 43758.5453123);
	}
	
	vec4 mod289(const in vec4 x) { return x - floor(x * (1. / 289.)) * 289.; }
	
	vec4 permute(const in vec4 v) { return mod289(((v * 34.0) + 1.0) * v); }
	
	vec4 taylorInvSqrt(in vec4 r) { return 1.79284291400159 - 0.85373472095314 * r; }
	
	vec4 grad4(float j, vec4 ip) {
		const vec4 ones = vec4(1.0, 1.0, 1.0, -1.0);
		vec4 p, s;
		p.xyz = floor(fract (vec3(j) * ip.xyz) * 7.0) * ip.z - 1.0;
		p.w = 1.5 - dot(abs(p.xyz), ones.xyz);
		s = vec4(lessThan(p, vec4(0.0)));
		p.xyz = p.xyz + (s.xyz*2.0 - 1.0) * s.www;
		return p;
	}
	
	vec2  quintic(const in vec2 v)  { return v*v*v*(v*(v*6.0-15.0)+10.0); }
	
	
	float pnoise(in vec2 P, in vec2 rep) {
		vec4 Pi = floor(P.xyxy) + vec4(0.0, 0.0, 1.0, 1.0);
		vec4 Pf = fract(P.xyxy) - vec4(0.0, 0.0, 1.0, 1.0);
		Pi = mod(Pi, rep.xyxy);// To create noise with explicit period
		Pi = mod289(Pi);// To avoid truncation effects in permutation
		vec4 ix = Pi.xzxz;
		vec4 iy = Pi.yyww;
		vec4 fx = Pf.xzxz;
		vec4 fy = Pf.yyww;
	
	
	
		vec4 i = permute(permute(ix) + iy);
	
		vec4 gx = fract(i * (1.0 / 41.0)) * 2.0 - 1.0;
		vec4 gy = abs(gx) - 0.5;
		vec4 tx = floor(gx + 0.5);
		gx = gx - tx;
	
	
	
		vec2 g00 = vec2(gx.x, gy.x);
		vec2 g10 = vec2(gx.y, gy.y);
		vec2 g01 = vec2(gx.z, gy.z);
		vec2 g11 = vec2(gx.w, gy.w);
	
		vec4 norm = taylorInvSqrt(vec4(dot(g00, g00), dot(g01, g01), dot(g10, g10), dot(g11, g11)));
		g00 *= norm.x;
		g01 *= norm.y;
		g10 *= norm.z;
		g11 *= norm.w;
	
	
		float n00 = dot(g00, vec2(fx.x, fy.x));
		float n10 = dot(g10, vec2(fx.y, fy.y));
		float n01 = dot(g01, vec2(fx.z, fy.z));
		float n11 = dot(g11, vec2(fx.w, fy.w));
	
		vec2 fade_xy = quintic(Pf.xy);
		vec2 n_x = mix(vec2(n00, n01), vec2(n10, n11), fade_xy.x);
		float n_xy = mix(n_x.x, n_x.y, fade_xy.y);
	
	
		return 2.3 * n_xy;
	}

    void main() {
		// Calculate the squared distance from the fragment position to the center of the quad
		float distanceFromCenterSquared = dot(vUv - vec2(0.5), vUv - vec2(0.5));
	
		vec3 finalColor = vColor;
	
		float polarAngleToCenter = abs(atan(vUv.y - 0.5, vUv.x - 0.5) / (3.14 / 2.));
	
		float angle = atan(vUv.y - 0.5, vUv.x - 0.5);
		float normalizedAngle = angle / (2.0 * 3.14159) + 0.5;
		float testColor = 1.0 - abs(2.0 * normalizedAngle - 1.0);
	
		float roundedPNoise = pnoise(vec2(testColor + vUv.y * 0.5, testColor + vUv.x * 1.2) + uTime * .52 + vSparklePhase * 200., vec2(5.2, 7.4));
	
		// Discard the fragment if its squared distance from the center is greater than the squared radius (0.25)
		if (distanceFromCenterSquared > 0.25 - max(roundedPNoise * 0.04, 0.)) {
			discard;
		}
	
	
		// finalColor *= sin((uTime + vSparklePhase * 50.0) * sparkleSpeed) * (sparkleAmount / 2.) + (1. - (sparkleAmount / 2.));
	
		float noiseDepth = 0.4;
		float noise = pnoise(vUv + (uTime* 0.2)+ vSparklePhase * 500., vec2(30.2, 13.4)) * noiseDepth + (1. - noiseDepth);
	
	
		// finalColor *= noise;
		
		vec3 color = mix(bgColor, finalColor, vDepthFade);
		gl_FragColor = vec4(color, 1.0);
    }
  `,
	side: DoubleSide,
	transparent: true,
	uniforms: {
		ballRadius: {
			value: 100,
		},
		circleRadius: {
			value: 0.5,
		},
		uTime: { value: 0 },
		sparkleAmount: {
			value: 0.3,
		},
		sparkleSpeed: {
			value: 0.7,
		},
		uRadialDisplacementAmplitude: {
			value: 0,
		},
		uRadialDisplacementSpeed: {
			value: 0,
		},
		uRadialDisplacementNoisiness: {
			value: 0,
		},
		uRandomPhiOffsetAmount: {
			value: 0,
		},
		uRandomThetaOffsetAmount: {
			value: 0,
		},
		uPhiDisplacementAmplitude: {
			value: 0,
		},
		uPhiDisplacementSpeed: {
			value: 0,
		},
		uPhiDisplacementNoisiness: {
			value: 0,
		},
		uThetaDisplacementAmplitude: {
			value: 0,
		},
		uThetaDisplacementSpeed: {
			value: 0,
		},
		uThetaDisplacementNoisiness: {
			value: 0,
		},
		uRotationSpeedX: {
			value: 0.1,
		},
		uRotationSpeedY: {
			value: 0.095,
		},
		uRotationSpeedZ: {
			value: 0,
		},
		color1: {
			value: new Color('#1862FF'),
		},
		color2: {
			value: new Color('#69edf2'),
		},
		bgColor: {
			value: new Color('#ff00ff'),
		},
	},
})

export const getTriGeometry = () => {
	const triGeometry = new BufferGeometry()
	// Create a triangle such that it completely covers a circle in clip space
	const vertices = new Float32Array([-Math.sqrt(3), -1, 0, Math.sqrt(3), -1, 0, 0, 2, 0])
	const indices = new Uint16Array([0, 1, 2])
	triGeometry.setIndex(new BufferAttribute(indices, 1))
	triGeometry.setAttribute('position', new BufferAttribute(vertices, 3))
	return triGeometry
}

const createParticles = (positions: number[]) => {
	const numInstances = positions.length / 3
	const instancedMesh = new InstancedMesh(getTriGeometry(), material, numInstances)

	const tempObject = new Object3D()
	const offsets = new Float32Array(numInstances * 3)

	for (let i = 0; i < numInstances; i++) {
		const j = i * 3

		const x = positions[j]
		const y = positions[j + 1]
		const z = positions[j + 2]

		tempObject.position.set(x, y, z)
		tempObject.updateMatrix()
		instancedMesh.setMatrixAt(i, tempObject.matrix)

		offsets.set([x, y, z], i * 3)
	}

	instancedMesh.geometry.setAttribute('instanceOffset', new InstancedBufferAttribute(offsets, 3))
	instancedMesh.geometry.setAttribute('instanceOriginal', new InstancedBufferAttribute(offsets, 3))
	instancedMesh.instanceMatrix.needsUpdate = true

	return instancedMesh
}
